@import "~bootstrap/scss/functions";

// === Website colors
$teal: #1cac9f;
$color-shark: #343638;
$color-light-primary: tint-color($teal, 30%);
$color-light-secondary: tint-color($color-shark, 10%);

// === Bootstrap
// == Grid
$desktop-breakpoint: lg;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1366px,
  xxl: 1680px
);

// == Colors
$primary: $teal;
$secondary: $color-shark;
$body-color: $secondary;
$body-bg: white;

// == Typography
$font-family-sans-serif: "Poppins", sans-serif;
$font-size-base: 1.0625rem !default; // 17px
$h1-font-size: 3.75rem !default; // 60px
$h2-font-size: 3rem !default; // 50px
$h3-font-size: 2.8125rem !default; // 45px
$h4-font-size: 1.5625rem !default; // 25px
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;
$home-font-sizes: (
  h1: $h1-font-size * 1.2 // 72px
);

// == Headings
$headings-font-weight: 700;

// == Buttons
$input-btn-padding-y: .5rem;
$input-btn-padding-x: 1rem;
$min-contrast-ratio: 2.5;

// == Navs
$nav-link-font-size: 1rem;

// === Alpha
// == Configuration
$menus: ("right");
$teases: ("grid", "simple");
$top-bar: true;

// == Dark style
$dark-bg-color: $body-color;

// == Featured image
$home-featured-image-height: calc(100vh - var(--full-header-height-default) - var(--extra-home-featured-height-offset));
$featured-image-overlay-opacity: .3;

// == Separate title
$separate-title-bg: $body-color;

// == Sidebar
$sidebar: true;

// == Surtitle
$alpha-surtitle-color: $body-color;
$alpha-surtitle-font-size: .9375rem;

// === Fonts optimization
// == FontAwesome Icons
@import "~@fortawesome/fontawesome-pro/scss/functions";
@import "~@fortawesome/fontawesome-pro/scss/variables";
$custom-used-fa-icons: (
  // == Arrows
  chevron-left: $fa-var-chevron-left,
  // == Buildings
  home-lg: $fa-var-home-lg,

  //arrow-right: $fa-var-arrow-right,
  // == Interfaces
  user: $fa-var-user,
  check: $fa-var-check,
  // == Objects
  eye: $fa-var-eye,
  star: $fa-var-star,
  sun: $fa-var-sun,
  // == Politicial
  ballot-check: $fa-var-ballot-check,
);
$used-fa-icons-groups: ("accommodations");

// === Paralaxx
$asp-overlay-bg-color: $secondary;
